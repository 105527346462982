import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TourFrame from "../components/TourFrame"

import BannerInterior from '../components/BannerInterior'
import poster from '../images/banner-acerca.jpg'
import video from '../videos/nosotros.mp4'

import CardBeneficio from '../components/CardBeneficio'
import mision from '../images/mision-thumb.jpg'
import vision from '../images/vision-thumb.jpg'

// import CarouselAcerca from '../components/CarouselAcerca'

const Acerca = () => (
	<Layout>

		<SEO title="Nosotros" />

		<BannerInterior
			small="Barcelona Eventos"
			title="Nosotros"
			caption=" "
			imgsrc={poster}
			videobg={video}
			textstyle="lights"
		/>

		<section className="historia">
			<div className="container">
				<div className="row">
					<div className="col-md-6 col-lg-5">
						<div className="heading-s1 mb-4">
							<h3 className="heading-titulo">Historia</h3>
							<p className="heading-caption">Creamos Barcelona Eventos para ser diferente a todos los salones</p>
						</div>
					</div>
					<div className="col-md-6 col-lg-5 offset-lg-1">
						<p>Tras varios viajes a Europa, en concreto a las ciudades de Barcelona y Roma, la idea de traer a Monterrey un poco de la historia, la cultura y la impresionante arquitectura clásica, fue tomando forma y se fue convirtiendo en realidad.</p>
						<p>Así, en 2014 nace Barcelona Eventos, salones que combinan la elegancia arquitectónica con la modernidad y comodidad de amenidades de primer nivel.</p>
						<p>Barcelona Eventos para nosotros, es un sueño europeo traído a Monterrey y esperamos que sea para ti tambien, un sueño que se vuelve realidad.</p>
					</div>
				</div>
				<div className="row mt-5">
					<div className="col-md-6 mb-5">
						<CardBeneficio
							titulo="Misión"
							caption="Ofrecer la mejor experiencia en eventos sociales y empresariales, siempre dando el mejor servicio, con el mejor catering en la zona metropolitana de Monterrey."
							imgsrc={mision}
						/>
					</div>
					<div className="col-md-6 mb-5">
						<CardBeneficio
							titulo="Visión"
							caption="Convertirnos en el lugar más buscado para realizar tu evento en Apodaca, estando siempre a la vanguardia en eventos."
							imgsrc={vision}
						/>
					</div>
				</div>
			</div>
		</section>

		<div className="mt-5">
			<TourFrame source="https://my.matterport.com/show/?m=DaSAW1EQtvz"></TourFrame>
		</div>
		{/* <CarouselAcerca /> */}

	</Layout>
)

export default Acerca
