import React, {Component} from "react"
import { SimpleImg } from 'react-simple-img'

class CardBeneficio extends Component{
	render(){
		return(
			<div className="card-beneficio">
				<figure>
					<SimpleImg src={this.props.imgsrc} placeholder={false} alt={this.props.titulo} />
					{/* <img src={this.props.imgsrc} alt={this.props.titulo}/> */}
					<figcaption>
						<h3>{this.props.titulo}</h3>
						<p>{this.props.caption}</p>
					</figcaption>
				</figure>
			</div>
		)
	}
}

export default CardBeneficio
