import React from 'react'

const TourFrame = (props) => {
	return (
		<section className="tour-frame">
			<iframe title="Tour" width="100%" height="500" src={props.source} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
		</section>
	)
}

export default TourFrame
