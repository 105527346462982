import React, { Component } from "react"

class BannerInterior extends Component{
	render(){
		return(
			<section className={`banner-interior ${this.props.textstyle}`}>
				<div className="container">
					<h1 className="title">
						<small>{this.props.small}</small>
						<span>{this.props.title}</span>
					</h1>
					{ this.props.caption ? <p>{this.props.caption}</p> : null }
				</div>
				{ this.props.videobg ? <video className="poster" width="1280" height="720" autoPlay loop muted playsInline><source src={this.props.videobg} type="video/mp4"/></video> : <img className="poster" src={this.props.imgsrc} alt={this.props.title}/> }
			</section>
		)
	}
}

export default BannerInterior
